import { useCallback, useMemo, useRef } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { getEventInDateRange, msToSeconds, strictIsEqual } from "./extensions";
import { TimelineHelpers } from "./useTimelineHelpers";
import { ElementHelpers } from "./useElementHelpers";

export default function useTimelineMetaEvents(TH: TimelineHelpers, elementHelpers: ElementHelpers) {
  const motionEvents = useRef<CameraEvent[]>([]);
  const noiseEvents = useRef<CameraEvent[]>([]);
  const eventTimes = useRef<{ [time: number]: boolean }>({});
  const eventsById = useRef<{ [eventId: string]: CameraEvent }>({});
  const metaEventsReference = useRef<CameraEvent[]>([]);
  const previousTime = useRef(0);

  const updateIndexedMap = useCallback((event: CameraEvent, remove?: boolean) => {
    const start = Math.round(msToSeconds(new Date(event.start).getTime()));
    if (!remove) {
      eventsById.current[event.uniqueId] = event;
      eventTimes.current[start] = true;
    } else {
      delete eventsById.current[event.uniqueId];
      delete eventTimes.current[start];
    }
  }, []);

  const getCorrespondingEventArray = (event: CameraEvent) => (event.type === "NOISE" ? noiseEvents : motionEvents);

  const addEventToMap = useCallback(
    (event: CameraEvent) => {
      if (event.type === "CONNECT" || event.type === "DISCONNECT") return;
      getCorrespondingEventArray(event).current.push(event);
      updateIndexedMap(event);
    },
    [updateIndexedMap]
  );

  const updateEventInMap = useCallback(
    (event: CameraEvent) => {
      if (event.type === "CONNECT" || event.type === "DISCONNECT") return;
      const eventArr = getCorrespondingEventArray(event);
      const index = eventArr.current.findIndex((e) => e.uniqueId === event.uniqueId);
      eventArr.current[index] = event;
      metaEventsReference.current.forEach((e, i) => {
        if (e.uniqueId === event.uniqueId) metaEventsReference.current[i] = event;
      });
      updateIndexedMap(event);
    },
    [updateIndexedMap]
  );

  const deleteEventInMap = useCallback(
    (event: CameraEvent) => {
      const eventArr = getCorrespondingEventArray(event);
      eventArr.current = eventArr.current.filter((e) => e.uniqueId !== event.uniqueId);
      updateIndexedMap(event, true);
    },
    [updateIndexedMap]
  );

  const deleteOldEvents = useCallback(() => {
    const timelineStartTime = TH.getStartTime();
    if (!timelineStartTime) return;

    const deleteIfShouldBeDeleted = (event: CameraEvent) => {
      if (!event.finished) return;
      const endTime = new Date(event.end).getTime();
      if (endTime <= timelineStartTime) {
        elementHelpers.removeElementById("id" + event.uniqueId);
        deleteEventInMap(event);
      }
    };

    [...motionEvents.current, ...noiseEvents.current].forEach(deleteIfShouldBeDeleted);
  }, [TH, deleteEventInMap, elementHelpers]);

  const deleteMappedEvents = useCallback(() => {
    motionEvents.current = [];
    noiseEvents.current = [];
    eventsById.current = {};
    eventTimes.current = {};
    metaEventsReference.current = [];
  }, []);

  const mapEvents = useCallback((cameraEvents: CameraEvent[]) => {
    cameraEvents.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
    cameraEvents.forEach((event) => {
      const start = Math.round(msToSeconds(new Date(event.start).getTime()));
      eventsById.current[event.uniqueId] = event;
      eventTimes.current[start] = true;
      if (event.type === "NOISE") noiseEvents.current.push(event);
      if (event.type === "MOTION") motionEvents.current.push(event);
    });
  }, []);

  const getClosestEventTimeInDirection = useCallback(
    (direction: "next" | "previous") => {
      let closestTime = 0;
      const currentTime = msToSeconds(TH.getCurrentPlayerDateTime());

      Object.keys(eventTimes.current).forEach((time) => {
        const keyInSeconds = Number(time);

        if (direction === "next") {
          if (!closestTime && keyInSeconds > currentTime) closestTime = keyInSeconds;
        }
        if (direction === "previous") {
          if (keyInSeconds + 2 < currentTime) closestTime = keyInSeconds;
        }
      });

      const nextEvents = Object.values(eventsById.current).filter(
        (e) => Math.round(msToSeconds(new Date(e.start).getTime())) === closestTime
      );
      if (!nextEvents || nextEvents.length === 0) return;
      return nextEvents[0].start;
    },
    [TH]
  );

  const updateSkipButtons = useCallback(() => {
    const nextEventTime = getClosestEventTimeInDirection("next");
    const previousEventTime = getClosestEventTimeInDirection("previous");
    const visibleTimelineEndTime = TH.getVisibleEndTime();
    const visibleTimelineStart = TH.getStartTime();

    dataSyncEmitter.emit("replay-skip-buttons-update", {
      next: nextEventTime && nextEventTime + 7000 < visibleTimelineEndTime,
      previous: visibleTimelineStart && previousEventTime && previousEventTime - 7000 > visibleTimelineStart
    });
  }, [TH, getClosestEventTimeInDirection]);

  const updateMetaEvents = useCallback((events: CameraEvent[]) => {
    const isStale = strictIsEqual(metaEventsReference.current, events);
    if (isStale) return;
    log.timeline("Updating meta events");
    metaEventsReference.current = events;
    const event = new CustomEvent("meta-events-update", { detail: { events } });
    document.dispatchEvent(event);
  }, []);

  const updateMetaEventsOnTimeUpdate = useCallback(() => {
    const time = Math.round(msToSeconds(TH.getCurrentPlayerDateTime()));

    if (time === previousTime.current) return;
    const foundEvents = Object.values(eventsById.current).filter(
      (e) => Math.round(msToSeconds(new Date(e.start).getTime())) === time
    );

    let currentEvents: CameraEvent[] = [...metaEventsReference.current, ...foundEvents];
    if (currentEvents.length === 0) return;
    // Remove duplicates
    currentEvents = currentEvents.filter((e, i, arr) => arr.findIndex((deep) => deep.uniqueId === e.uniqueId) === i);

    currentEvents = currentEvents.filter((event) => {
      const isOutOfDate =
        event.finished &&
        (time > Math.round(msToSeconds(new Date(event.end).getTime())) ||
          time < Math.round(msToSeconds(new Date(event.start).getTime())));
      if (isOutOfDate) log.timeline("Found out of date meta event ", event);
      return !isOutOfDate;
    });

    updateMetaEvents(currentEvents);
    previousTime.current = time;
  }, [TH, updateMetaEvents]);

  const updateMetaEventOnSeek = useCallback(() => {
    const currentDate = new Date(TH.getCurrentPlayerDateTime());

    const update: CameraEvent[] = [];
    const ongoingMotionEvent = getEventInDateRange(motionEvents.current, currentDate);
    const ongoingNoiseEvent = getEventInDateRange(noiseEvents.current, currentDate);

    if (ongoingMotionEvent) update.push(ongoingMotionEvent);
    if (ongoingNoiseEvent) update.push(ongoingNoiseEvent);

    updateMetaEvents(update);
  }, [TH, updateMetaEvents]);

  return useMemo(
    () => ({
      mapEvents,
      updateMetaEventsOnTimeUpdate,
      updateMetaEventOnSeek,
      deleteMappedEvents,
      addEventToMap,
      updateEventInMap,
      getClosestEventTimeInDirection,
      updateSkipButtons,
      deleteOldEvents
    }),
    [
      addEventToMap,
      deleteMappedEvents,
      deleteOldEvents,
      getClosestEventTimeInDirection,
      mapEvents,
      updateEventInMap,
      updateMetaEventOnSeek,
      updateMetaEventsOnTimeUpdate,
      updateSkipButtons
    ]
  );
}
