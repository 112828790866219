import { useCallback, useEffect, useRef, useState } from "react";
import { Box, chakra, Image, Spinner } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { absoluteCenter } from "@/theme/utils/style";
import { useViewer } from "viewer/store/viewer";
import { useVideoMode } from "../utils";

type Props = { jid: string };

const EnhancedVideo = chakra("video");

export default function Video({ jid }: Props) {
  const [waitingForStream, setWaitingForStream] = useState(true);
  const [forceMuted, setForceMuted] = useState(false);
  const stream = useViewer((s) => s.remoteStreams)[jid];
  const preview = useViewer((s) => s.cameraPreviews)[jid];
  const muted = useViewer((s) => s.mutedCameras)[jid];
  const { getModeForCamera } = useVideoMode(jid);

  const videoMode = getModeForCamera();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (stream && videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  const handleMuteWhenReplayIsActive = useCallback((isReplayActive: boolean) => setForceMuted(isReplayActive), []);

  useEffect(() => {
    return dataSyncEmitter.on("replay-open-state-change", handleMuteWhenReplayIsActive);
  }, [handleMuteWhenReplayIsActive]);

  return (
    <Box pos="relative" zIndex={-1} w="100%" h="100%">
      <EnhancedVideo
        onLoadedData={() => setWaitingForStream(false)}
        muted={muted || forceMuted}
        autoPlay
        ref={videoRef}
        maxW="100vw"
        maxH="100vh"
        w="100%"
        h="100%"
        objectFit={videoMode}
        bg="black"
      />
      {waitingForStream && (
        <Spinner
          zIndex={10}
          size="lg"
          position="absolute"
          left="50%"
          top="50%"
          transform="translate(-50%, -50%)"
          color="neutral.99"
        />
      )}
      <AnimatePresence initial={false}>
        {preview?.image?.url && waitingForStream && (
          <Box as={motion.div} initial={{ opacity: 0 }} exit={{ opacity: 0, transition: { duration: 0.7 } }}>
            <Image
              {...absoluteCenter}
              filter="blur(8px)"
              objectFit="contain"
              alt="camera preview"
              src={preview.image.url}
              w="100%"
              h="100%"
            />
          </Box>
        )}
      </AnimatePresence>
    </Box>
  );
}
