import { Box, Button, Spinner, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "@/utils";
import { absoluteCenter } from "@/theme/utils/style";
import { useTransparentize } from "@/theme/utils/transparentize";
import Icon from "@/components/Icon";
import Circle from "@/components/Circle";
import IconButton from "@/components/IconButton";
import { useConnect } from "viewer/modules/monitoring/ConnectProvider";
import PlayIcon from "assets/icons/play.svg?react";
import { useSelectedEvent } from "viewer/modules/activityLog/SelectedEventProvider";
import ImageWithBlurredBg from "viewer/modules/station/components/ImageWithBlurredBg";

export default function PreviewOverlay({
  handlePlay,
  requestedPlay,
  error
}: {
  handlePlay: Cb;
  requestedPlay: boolean;
  error: boolean;
}) {
  const { connect } = useConnect();
  const { jid, isOngoingAndLive, previewUrl, listId } = useSelectedEvent();
  const buttonBg = useTransparentize("neutral-variant.20", 0.7);

  const goToLive = () => {
    if (jid) connect(jid, listId);
  };

  return (
    <Box
      w="100%"
      h="100%"
      pos="absolute"
      zIndex={3}
      bg="surface-variant"
      as={motion.button}
      onClick={isOngoingAndLive ? goToLive : handlePlay}
    >
      {previewUrl && <ImageWithBlurredBg src={previewUrl} />}

      {isOngoingAndLive && (
        <Button as="span" variant="blurred" {...absoluteCenter} display="flex" columnGap="0.25rem">
          <Circle bg="green.50" size="12" />
          <Text variant="label" color="neutral.99">
            {t("openLiveVideo")}
          </Text>
          <Icon icon={PlayIcon} size="16" fill="neutral.99" />
        </Button>
      )}

      {!isOngoingAndLive &&
        (requestedPlay && !error ? (
          <Circle
            size="64px"
            bg={buttonBg}
            props={{
              justifyContent: "center",
              alignItems: "center",
              ...absoluteCenter,
              zIndex: 4
            }}
          >
            <Spinner />
          </Circle>
        ) : (
          <IconButton
            as="span"
            icon={PlayIcon}
            iconSize="3rem"
            bg={buttonBg}
            tooltip={{ label: t("buttons.play") }}
            props={{
              ...absoluteCenter,
              zIndex: 4
            }}
          />
        ))}
    </Box>
  );
}
