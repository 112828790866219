import { Flex, FlexProps, Image as ChakraImage, ImageProps } from "@chakra-ui/react";
import { absoluteCenter } from "@/theme/utils/style";

export default function ImageWithBlurredBg({
  src,
  alt,
  blur = 12,
  displayForegroundImage = true,
  containerProps,
  imageProps
}: {
  src: string;
  alt?: string;
  blur?: number;
  displayForegroundImage?: boolean;
  containerProps?: FlexProps;
  imageProps?: ImageProps;
}) {
  return (
    <Flex w="100%" h="100%" pos="relative" {...containerProps}>
      <ChakraImage
        src={src}
        alt={"blurred" + alt}
        style={{
          filter: `blur(${blur}px)`,
          width: "100%",
          height: "100%",
          objectFit: "cover"
        }}
        {...imageProps}
      />

      {displayForegroundImage && <ChakraImage src={src} alt={alt} objectFit="contain" h="100%" {...absoluteCenter} />}
    </Flex>
  );
}
