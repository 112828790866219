import { useCallback, useEffect, useRef, useState } from "react";
import { Box, ChakraProps, Flex, Portal, scaleFadeConfig } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { t } from "@/utils";
import { useControls } from "@/hooks";
import Dialog from "@/components/Dialog";
import IconButton from "@/components/IconButton";
import { useTransparentize } from "@/theme/utils/transparentize";
import { dataSyncEmitter } from "@/modules/events/emitter";
import Timeline from "viewer/lib/Timeline/Timeline";
import ArrowLeft from "assets/icons/arrow_left.svg?react";
import Pause from "assets/icons/pause.svg?react";
import Play from "assets/icons/play.svg?react";
import Skip from "assets/icons/skip.svg?react";
import HdVideoButton from "../HdVideoButton";
import { useViewerActions } from "../../MonitoringManager";

type Props = { actionController: ActionController };
type Orientation = "landscape" | "vertical";
type ManualRotation = { isEnabled: boolean; orientation: Orientation };

const config = { ...scaleFadeConfig, custom: { initialScale: 0.95, reverse: true } };

export default function Replay({ actionController }: Props) {
  const timelineControls = useControls();
  const errorControls = useControls();
  const closeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isTimelineReady, setIsTimelineReady] = useState(false);
  const [manualRotation, setManualRotation] = useState<ManualRotation>({ isEnabled: false, orientation: "landscape" });
  const { startTimelinePlayer, destroyTimelinePlayer } = useViewerActions();
  const bg = useTransparentize("neutral-variant.30", 0.7);

  const closeTimeline = useCallback(
    async (error?: boolean) => {
      try {
        destroyTimelinePlayer();
        timelineControls.close();
        resetAfterClose();
        if (error) errorControls.open();
      } catch (err) {
        log.err(err);
        errorControls.open();
      }
    },
    [destroyTimelinePlayer, timelineControls, errorControls]
  );
  const closeTimelineOnError = useCallback(() => closeTimeline(true), [closeTimeline]);

  const resetAfterClose = () => {
    closeTimeoutRef.current = setTimeout(() => {
      clearTimeout(closeTimeoutRef.current!);
      closeTimeoutRef.current = null;
      setIsTimelineReady(false);
      setManualRotation({ isEnabled: false, orientation: "landscape" });
    }, 300);
  };

  useEffect(() => {
    actionController.addAction(timelineControls.open, "replay");
  }, [actionController, timelineControls, startTimelinePlayer]);

  useEffect(() => {
    dataSyncEmitter.emit("replay-open-state-change", timelineControls.isOpen);
  }, [timelineControls.isOpen]);

  useEffect(() => {
    return dataSyncEmitter.on("timeline-ready", () => setIsTimelineReady(true));
  });

  const onPlayerOrientationChange = useCallback(
    (manualRotation: ManualRotation) => setManualRotation(manualRotation),
    []
  );

  useEffect(() => {
    dataSyncEmitter.on("player-orientation-change", onPlayerOrientationChange);
    dataSyncEmitter.on("timeline-player-error", closeTimelineOnError);
    return () => {
      dataSyncEmitter.off("player-orientation-change", onPlayerOrientationChange);
      dataSyncEmitter.off("timeline-player-error", closeTimelineOnError);
    };
  }, [closeTimelineOnError, onPlayerOrientationChange]);

  const isVertical = manualRotation.orientation === "vertical";
  return (
    <>
      <AnimatePresence>
        {timelineControls.isOpen && (
          <Portal>
            <Box
              as={motion.div}
              w="100vw"
              h="100vh"
              pos="fixed"
              top="0px"
              left="0px"
              bg="black"
              zIndex={2}
              {...(config as ChakraProps)}
            >
              <IconButton
                bg={bg}
                icon={ArrowLeft}
                tooltip={{ label: t("back") }}
                props={{
                  pos: "absolute",
                  left: "32",
                  top: "32",
                  zIndex: 10
                }}
                onClick={() => closeTimeline()}
              />
              <HdVideoButton style={{ right: "32" }} />
              <ControlButtons bg={bg} isReady={isTimelineReady} />
              <Box
                pos="relative"
                w="100%"
                h="100%"
                sx={{
                  video: {
                    maxH: "100vh",
                    maxW: "100vw",
                    objectFit: "contain",
                    position: "static !important",
                    bg: "black",
                    ...(manualRotation.isEnabled && {
                      width: isVertical ? "auto !important" : "100% !important",
                      height: isVertical ? "100% !important" : "auto !important",
                      aspectRatio: isVertical ? "9/16" : "16/9",
                      objectFit: "fill"
                    })
                  },
                  ".playlist_video-dimensions": {
                    width: "100% !important",
                    height: "100% !important",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
                }}
              >
                <Timeline onReady={startTimelinePlayer} />
              </Box>
              <Box
                position="absolute"
                bottom="0"
                left="0"
                width="100%"
                height="30%"
                bg="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%)"
              />
            </Box>
          </Portal>
        )}
      </AnimatePresence>
      <Dialog
        isOpen={errorControls.isOpen}
        onClose={errorControls.close}
        title={t("somethingWentWrong")}
        body={t("tryAgainLater")}
        buttons={{
          cancel: { label: t("buttons.ok") }
        }}
      />
    </>
  );
}

const PlayButton = () => {
  const [state, setState] = useState<"playing" | "resting">("playing");

  useEffect(() => {
    const listeners = [
      dataSyncEmitter.on("timeline-playing", () => setState("playing")),
      dataSyncEmitter.on("timeline-pause", () => setState("resting"))
    ];

    return () => listeners.forEach((l) => l());
  }, []);

  const toggle = () => {
    if (state === "playing") {
      dataSyncEmitter.emit("timeline-should-pause");
      setState("resting");
    } else {
      dataSyncEmitter.emit("timeline-should-play");
      setState("playing");
    }
  };

  return (
    <Box zIndex={2}>
      <IconButton
        onClick={toggle}
        icon={state === "playing" ? Pause : Play}
        tooltip={{ label: t(state === "playing" ? "pause" : "buttons.play") }}
        bg="primary.90"
        fill="primary.10"
        props={{
          borderRadius: "12px"
        }}
      />
    </Box>
  );
};

const ControlButtons = ({ bg, isReady }: { bg: string; isReady: boolean }) => {
  const [enabledNext, setEnabledNext] = useState(false);
  const [enabledPrevious, setEnabledPrevious] = useState(false);

  const previousEvent = () => dataSyncEmitter.emit("timeline-skip-to-previous-event");
  const nextEvent = () => dataSyncEmitter.emit("timeline-skip-to-next-event");

  useEffect(() => {
    const onButtonsUpdate = ({ next, previous }: { next: boolean; previous: boolean }) => {
      setEnabledNext(next);
      setEnabledPrevious(previous);
    };

    dataSyncEmitter.on("replay-skip-buttons-update", onButtonsUpdate);
    return () => {
      dataSyncEmitter.off("replay-skip-buttons-update", onButtonsUpdate);
    };
  }, []);

  return (
    <Flex
      align="center"
      columnGap="8"
      zIndex={2}
      pos="absolute"
      left="32px"
      bottom="22px"
      transition="opacity 0.2s ease-in-out"
      opacity={isReady ? 1 : 0}
    >
      <IconButton
        tooltip={{ label: t("previous") }}
        icon={Skip}
        onClick={previousEvent}
        fill="neutral.99"
        bg={bg}
        props={{
          transform: "rotate(180deg)",
          disabled: !enabledPrevious,
          _disabled: { opacity: 0.4, cursor: "not-allowed" }
        }}
      />
      <PlayButton />
      <IconButton
        tooltip={{ label: t("buttons.next") }}
        icon={Skip}
        onClick={nextEvent}
        fill="neutral.99"
        bg={bg}
        props={{
          disabled: !enabledNext,
          _disabled: { opacity: 0.4, cursor: "not-allowed" }
        }}
      />
    </Flex>
  );
};
