import { useCallback, useRef } from "react";
import { hashCode, msToPx } from "./extensions";
import { PRange, RefList } from "./useTimelineUpdater";
import { ElementHelpers } from "./useElementHelpers";
import { TimelineHelpers } from "./useTimelineHelpers";

type Props = {
  refs: RefList;
  elementHelpers: ElementHelpers;
  TH: TimelineHelpers;
};

export default function useVideoRanges({ elementHelpers, refs, TH }: Props) {
  const { timelineStartDate, timeline } = refs;
  const lastReceivedVideoRanges = useRef<TimeRange[] | null>(null);

  const renderTimerange = useCallback(
    (range: PRange) => {
      const playableRangeEl = document.createElement("div");
      playableRangeEl.id = range.id;
      playableRangeEl.style.height = "22px";
      playableRangeEl.style.backgroundColor = "#2E3038";
      playableRangeEl.style.zIndex = "0";
      playableRangeEl.style.position = "absolute";
      playableRangeEl.style.left = elementHelpers.getPositionFromCurrentStartInPx(range.start);
      playableRangeEl.style.width = msToPx(range.duration) + "px";
      elementHelpers.renderElementOnTimeline(playableRangeEl);
    },
    [elementHelpers]
  );

  const getTimerangeData = (r: TimeRange) => {
    const startTime = new Date(r.start).getTime();
    const endTime = new Date(r.end).getTime();
    return { startTime, endTime, id: "id" + hashCode(String(startTime + endTime)) };
  };

  const renderPlayableRanges = useCallback(
    (e: Event, shouldUpdate?: boolean) => {
      if (!timelineStartDate.current || !timeline.current) return;
      const {
        detail: { videoRanges }
      } = e as TimelinePlaylistEvent;
      const compareTimerange = (a: TimeRange, b: TimeRange) => a.start === b.start && a.end === b.end;

      const newRanges = videoRanges.filter(
        (r) => !lastReceivedVideoRanges.current?.some((l) => compareTimerange(r, l))
      );
      const oldRanges = lastReceivedVideoRanges.current?.filter(
        (r) => !videoRanges.some((l) => compareTimerange(r, l))
      );

      newRanges.forEach((r) => {
        const { endTime, startTime, id } = getTimerangeData(r);
        const range: PRange = {
          id: id,
          start: startTime,
          end: endTime,
          duration: elementHelpers.getRangeDurationInMs(startTime, endTime)
        };
        renderTimerange(range);
      });
      oldRanges?.forEach((r) => elementHelpers.removeElementById(getTimerangeData(r).id));

      if (shouldUpdate) {
        videoRanges.forEach((r) => {
          const { id, startTime } = getTimerangeData(r);
          const playableRangeEl = elementHelpers.getElementById(id) as HTMLDivElement | null;
          if (playableRangeEl) playableRangeEl.style.left = elementHelpers.getPositionFromCurrentStartInPx(startTime);
        });
      }
      lastReceivedVideoRanges.current = videoRanges;
    },
    [elementHelpers, timeline, timelineStartDate, renderTimerange]
  );

  const hasVideoDataForCurrentTime = () => {
    const currentTime = TH.getCurrentPlayerDateTime();
    let hasData = false;
    lastReceivedVideoRanges.current?.forEach((range) => {
      const { start, end } = range;
      if (currentTime > start && currentTime <= start + (end - start)) hasData = true;
    });
    return hasData;
  };

  return { renderPlayableRanges, hasVideoDataForCurrentTime };
}
