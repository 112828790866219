import { cloudCode } from "@/modules/cloud";

type GetCameraHistory = {
  cameras?: string[];
  events?: string[];
  start?: number;
  end?: number;
};

export async function getCameraHistoryEvents({
  lastEventId,
  cameras,
  events,
  start,
  end
}: GetCameraHistory & { lastEventId?: string }) {
  const cameraEvents: ParseCameraEvent[] | null = await cloudCode.run("getCameraHistoryEvents", {
    pageSize: 20,
    lastEventId,
    cameras,
    events,
    start,
    end
  });
  return cameraEvents;
}

export async function getNewerCameraHistoryEvents({
  initialEventId,
  cameras,
  events
}: GetCameraHistory & { initialEventId?: string }) {
  const cameraEvents: ParseCameraEvent[] = await cloudCode.run("getNewerCameraHistoryEvents", {
    initialEventId,
    cameras,
    events
  });
  return cameraEvents;
}

export async function deleteCameraHistoryEvent(objectId: string) {
  const response = await cloudCode.run("deleteCameraHistoryEventByObjectId", {
    eventId: objectId
  });
  return response;
}
