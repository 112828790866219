import { useEffect, useMemo, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useControls } from "@/hooks";
import { useDevices } from "@/store/devices";
import { monitoringActionKeys } from "@/enums/monitoringActionKeys";
import LocalVideo from "viewer/modules/monitoring/CameraView/LocalVideo";
import createActionController, { useActionEvents } from "viewer/modules/monitoring/actionController";
import { getCurrentCameraState } from "viewer/store/viewer/selectors";
import { useViewer } from "viewer/store/viewer";
import MonitoringErrorOverlay from "./MonitoringErrorOverlay";
import CameraViewControls from "./CameraViewControls";
import ControlsEditDialog from "./ControlsEditDialog";
import MonitoringDialog from "./MonitoringDialog";
import FlashlightSlider from "./FlashlightSlider";
import BottomPanel from "./BottomPanel";
import CameraInfo from "./CameraInfo";
import LeftPanel from "./LeftPanel";
import Gradient from "./Gradient";
import Video from "./Video";

type Props = {
  listId: number;
  jid: string;
  disconnect: Cb;
  openCameraHistory: Cb;
  openSettings: Cb;
};

const actionController = createActionController();

export default function CameraView({ listId, jid, disconnect, openCameraHistory, openSettings }: Props) {
  useActionEvents({ openCameraHistory, actionController });

  const [actionStates, setActionStates] = useState<ActionStates>(actionController.providing.getCurrentStates());
  const device = useDevices((s) => s.devices)[jid] as StoreCameraDevice;
  const cameraState = useViewer(getCurrentCameraState);
  const editDialog = useControls();

  const mappedControls = useMemo(() => {
    const { map } = actionController;
    const mapper = (name: CameraControlKey) => map[name];
    const filter = (name: CameraControlKey) => Boolean(monitoringActionKeys[name]) || name === ("" as CameraControlKey);

    if (!device || !device.cameraControls) return null;
    const { primaryControls, secondaryControls } = device.cameraControls;
    return {
      primaryControls: primaryControls.filter(filter).map(mapper),
      secondaryControls: secondaryControls.map(mapper)
    };
  }, [device]);

  useEffect(() => {
    actionController.on("state-update", setActionStates);
    actionController.setInitialState(jid);
    return () => actionController.off("state-update", setActionStates);
  }, [jid]);

  const isConnected = Boolean(cameraState);
  return (
    <Box
      w="100vw"
      h="100vh"
      pos="fixed"
      top="0px"
      left="0px"
      as={motion.div}
      layoutId={`camera-view-${listId}`}
      bg="black"
      zIndex="2"
    >
      <Flex pos="relative" w="100%" h="100%" justify="center" align="center">
        <CameraViewControls disconnect={disconnect} openSettings={openSettings} />
        {isConnected && (
          <>
            {mappedControls && (
              <BottomPanel
                actionStates={actionStates}
                items={mappedControls.primaryControls}
                actionController={actionController}
              />
            )}
            {mappedControls && (
              <LeftPanel
                actionStates={actionStates}
                items={mappedControls.secondaryControls}
                actionController={actionController}
                openEditDialog={editDialog.open}
              />
            )}
            <CameraInfo />
          </>
        )}
        <Video jid={jid} />
        {mappedControls && editDialog.isOpen && (
          <ControlsEditDialog
            close={editDialog.close}
            actionController={actionController}
            actionStates={actionStates}
            jid={jid}
          />
        )}
        <LocalVideo activeFlashlight={actionStates.flashlight.isActive && Boolean(cameraState?.flashlightAdjustable)} />
        {actionStates.flashlight.isActive && <FlashlightSlider />}
        <MonitoringDialog />
        <MonitoringErrorOverlay />
      </Flex>
      <Gradient />
    </Box>
  );
}
