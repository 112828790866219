import React, { useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import Player from "video.js/dist/types/player";
import Fullscreen from "assets/icons/scale_to_fill.svg?react";
import Play from "assets/icons/play.svg?react";
import Pause from "assets/icons/pause.svg?react";
import Volume from "assets/icons/volume_on.svg?react";
import Volume_muted from "assets/icons/volume_off.svg?react";
import { addGuard } from "@/utils";
import { t } from "@/utils";
import Time from "@/lib/Time";
import IconButton from "@/components/IconButton";
import SeekBar from "./SeekBar";

type ControlProps = {
  playerRef: React.RefObject<Player>;
  currentTime: number;
  handleFullScreen: Cb;
  playerStatus: "resting" | "playing";
  setPlayerStatus: (param: "resting" | "playing") => void;
};

const buttonIcons = {
  playing: Pause,
  resting: Play
};

const Controls = ({ playerRef, currentTime, handleFullScreen, playerStatus, setPlayerStatus }: ControlProps) => {
  const [muted, setMuted] = useState(isDev);

  const handleMute = () => {
    if (!playerRef.current) return;
    const mute = !muted;
    setMuted(mute);
    playerRef.current.muted(mute);
  };

  const play = () => {
    addGuard(() => playerRef.current!.play(), { onError: (err) => log.err(err) });
    setPlayerStatus("playing");
  };

  const pause = () => {
    addGuard(() => playerRef.current!.pause(), { onError: (err) => log.err(err) });
    setPlayerStatus("resting");
  };

  const handlePlayOrPause = () => {
    if (playerRef.current?.paused()) play();
    else pause();
  };

  return (
    <Flex pos="absolute" width="100%" bottom="0.5rem" alignItems="center" userSelect="none" zIndex={2}>
      <IconButton
        icon={buttonIcons[playerStatus]}
        tooltip={{ label: t(playerStatus === "playing" ? "pause" : "buttons.play") }}
        id="playStop"
        fill="white"
        props={{
          mx: ["0.5rem", null, "1.5rem"],
          onMouseDown: handlePlayOrPause
        }}
      />
      <Text
        sx={{
          color: "white",
          mr: "1rem",
          fontSize: "0.75rem",
          fontWeight: 500,
          lineHeight: "1rem",
          letterSpacing: " 0.03125rem"
        }}
      >
        {Time.formatSeconds(currentTime)}
      </Text>
      <SeekBar
        currentTime={currentTime}
        duration={playerRef.current?.duration() || 0}
        setPlayerTime={(val) => playerRef.current?.currentTime(val)}
        stop={pause}
        play={play}
      />
      <Text
        id="duration"
        sx={{
          mr: ["0.5rem", null, "1rem"],
          ml: ["1rem", null, "1.5rem"],
          color: "white",
          fontSize: "0.75rem",
          fontWeight: 500,
          lineHeight: "1rem",
          letterSpacing: " 0.03125rem"
        }}
      >
        {playerRef.current?.duration && Time.formatSeconds(playerRef.current?.duration() || 0)}
      </Text>
      <IconButton
        icon={muted ? Volume_muted : Volume}
        tooltip={{ label: t(muted ? "buttons.unmute" : "buttons.mute") }}
        fill="white"
        iconSize="1.5rem"
        props={{ onMouseDown: handleMute }}
      />
      <IconButton
        icon={Fullscreen}
        fill="white"
        onClick={handleFullScreen}
        tooltip={{ label: t("fullscreen.enter") }}
        props={{ mr: ["0.5rem", null, "1.5rem"] }}
      />
    </Flex>
  );
};

export default Controls;
