import { useState, useMemo, useRef } from "react";
import { Box, Flex, Text, Heading } from "@chakra-ui/react";
import TrashIcon from "assets/icons/trash.svg?react";
import ErrorIcon from "assets/icons/error.svg?react";
import DownloadIcon from "assets/icons/download_file.svg?react";
import { EventColors, EventIcons, EventStringKeys } from "@/enums/events";
import { useControls } from "@/hooks/useControls";
import { downloadFile, t } from "@/utils";
import Time from "@/lib/Time";
import Icon from "@/components/Icon";
import Circle from "@/components/Circle";
import IconButton from "@/components/IconButton";
import Dialog from "@/components/Dialog";
import { deleteCameraHistoryEvent } from "viewer/modules/cloud/media";
import { useActivityLog } from "viewer/store/activityLog";
import { useSelectedEvent } from "../../SelectedEventProvider";
import EventPlayer from "../EventPlayer";
import ArtificialProgress, { ProgressController } from "../ArtificialProgress";
import { usePremiumScreen } from "@/modules/app/PremiumScreen";
import dayjs from "dayjs";

const getDeleteDialogProps = () =>
  ({
    title: {
      error: t("somethingWentWrong"),
      confirm: t("deleteEvent.title")
    },
    icon: {
      confirm: TrashIcon,
      error: ErrorIcon
    },
    body: {
      error: t("dialog.failed.description"),
      confirm: t("deleteEvent.description")
    },
    buttons: {
      error: { confirm: { label: t("buttons.ok") } },
      confirm: {
        cancel: { label: t("buttons.keep") },
        confirm: { label: t("buttons.delete"), color: "error" }
      }
    }
  } as const);

export default function EventDetailContent() {
  const [deleteStep, setDeleteStep] = useState<"confirm" | "error">("confirm");
  const [isProcessing, setIsProcessing] = useState(false);
  const downloadProgressController = useRef<ProgressController | null>(null);
  const abort = useRef<Cb | null>(null);
  const { event, device, isOngoingAndOffline, isOngoingAndLive } = useSelectedEvent();
  const { displayBannerForFreeUser } = usePremiumScreen();
  const removeEvent = useActivityLog((state) => state.removeEvent);

  const downloadDialog = useControls();
  const deleteDialog = useControls();
  const deleteDialogProps = useMemo(() => getDeleteDialogProps(), []);

  const deleteEvent = async () => {
    setIsProcessing(true);
    const response = await deleteCameraHistoryEvent(event!.objectId!);
    if (!response) {
      setDeleteStep("error");
      setIsProcessing(false);
      return;
    }
    removeEvent(event!.uniqueId);
    deleteDialog.close();
    setIsProcessing(false);
  };

  const resetDialogState = () => {
    deleteDialog.close();
    setDeleteStep("confirm");
  };

  const downloadEvent = () => displayBannerForFreeUser("download").elseRun(startDownload);

  const startDownload = async () => {
    if (!event?.videoUrl) return;
    downloadDialog.open();
    try {
      let data: any;
      const xmlHTTP = new XMLHttpRequest();
      xmlHTTP.open("GET", event.videoUrl, true);
      xmlHTTP.responseType = "arraybuffer";
      abort.current = () => xmlHTTP.abort();

      xmlHTTP.onload = () => (data = xmlHTTP.response);
      xmlHTTP.onprogress = function (pr) {
        downloadProgressController.current?.end();
        const baseProgress = pr.loaded / pr.total;
        const progress = 0.5 + baseProgress * 0.5;
        downloadProgressController.current?.set(progress);
      };
      xmlHTTP.onloadend = function (e) {
        const date = dayjs(event.start).format("MM-dd-YYYY_HH-mm-ss");
        downloadFile({ data: new Uint8Array(data), filename: `camerito_${date}.mp4` });
        downloadDialog.close();
      };
      xmlHTTP.send();
    } catch (err) {
      log.err("Download failed");
    }
  };

  const cancelDownload = () => {
    abort.current?.();
    downloadDialog.close();
  };

  if (!event) return null;
  const { start, end, type } = event;
  return (
    <Box bg="surface1" borderRadius="1rem">
      <EventPlayer url={event.playlistUrl} />
      <Flex flexDir="column" px={["1.5rem", "1rem", "1.5rem"]} pt="1rem" pb="1.5rem">
        <Flex mb="0.75rem" alignItems="center" columnGap="0.75rem">
          <Circle size="2rem" bg={EventColors[event.type].secondary}>
            <Icon icon={EventIcons[event.type]} fill={EventColors[event.type].primary} size="16" />
          </Circle>
          <Heading as="h5">
            {Time.formatDate(start, "HH:mm")} · {t(EventStringKeys[type])}
          </Heading>
        </Flex>
        <Flex justifyContent="space-between" flexWrap="wrap" gap="0.75rem">
          <Flex>
            <Box mr="0.75rem">
              <Text color="on-surface">{t("duration")}</Text>
              {end && <Text color="on-surface">{t("interval")}</Text>}
              <Text color="on-surface">{t("camera")}</Text>
            </Box>
            <Box color="on-surface-variant">
              <Text>
                {end
                  ? Time.formatDuration(end - start)
                  : isOngoingAndOffline
                  ? t("unfinishedEvent")
                  : t("ongoingEvent")}
              </Text>
              {end && (
                <Text>
                  {Time.formatDate(start, "HH:mm:ss") + " - "}
                  {Time.formatDate(end, "HH:mm:ss")}
                </Text>
              )}
              <Text noOfLines={1} maxW={250}>
                {device?.name || "unknown"}
              </Text>
            </Box>
          </Flex>
          <Flex columnGap="1rem" alignSelf="flex-end">
            {end && (
              <IconButton
                onClick={deleteDialog.open}
                icon={TrashIcon}
                fill="on-surface"
                bg="surface1"
                tooltip={{ label: t("buttons.delete") }}
              />
            )}
            <Dialog
              isProcessing={isProcessing}
              onClose={deleteDialog.close}
              isOpen={deleteDialog.isOpen}
              onConfirm={deleteStep === "confirm" ? deleteEvent : resetDialogState}
              iconProps={{ fill: "error" }}
              icon={deleteDialogProps.icon[deleteStep]}
              title={deleteDialogProps.title[deleteStep]}
              body={deleteDialogProps.body[deleteStep]}
              buttons={deleteDialogProps.buttons[deleteStep]}
            />
            {!isOngoingAndLive && (
              <IconButton
                onClick={downloadEvent}
                icon={DownloadIcon}
                fill="on-surface"
                bg="surface1"
                tooltip={{ label: t("download") }}
              />
            )}
            <Dialog
              isOpen={downloadDialog.isOpen}
              onClose={cancelDownload}
              title={t("preparing")}
              buttons={{ cancel: { label: t("buttons.cancel") } }}
              body={
                <>
                  <Text mb="28">{t("videoIsBeingPrepared")}</Text>
                  <ArtificialProgress
                    duration={end ? end - start : undefined}
                    controllerRef={downloadProgressController}
                  />
                </>
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
