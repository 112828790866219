import { Flex, Badge, Text, Button, Heading } from "@chakra-ui/react";
import { t, openCameritoWeb } from "@/utils";
import { useAuth } from "@/store/auth";
import { getHasActiveSubscription } from "@/store/auth/selectors";
import { useDevices } from "@/store/devices";
import { getCameraDevices } from "@/store/devices/selectors";

export default function DashboardPremiumBanner() {
  const isPremium = useAuth(getHasActiveSubscription);
  const hasPairedCameras = useDevices(getCameraDevices).length > 0;

  if (isPremium || !hasPairedCameras) return null;
  return (
    <Flex
      alignItems="center"
      borderRadius="1rem"
      bg="secondary-container"
      py="0.75rem"
      px="1rem"
      columnGap="0.75rem"
      w="100%"
      rowGap="0.75rem"
      mb="1.5rem"
      flexDir={["column", "row", null]}
      flexWrap={["wrap", "nowrap", null]}
    >
      <Badge variant="premium-gradient">
        <Text variant="label2" color="orange.40" lineHeight="0.875rem" fontSize="11px">
          {t("user.premium").toUpperCase()}
        </Text>
      </Badge>
      <Heading flexGrow="1" as="h5" color="on-secondary-container" textAlign={["center", "start"]}>
        {t("tryProVersion")}
      </Heading>
      <Button
        py="12"
        px="24"
        fontSize="md"
        borderRadius="24"
        fontWeight="medium"
        letterSpacing="0.5px"
        onClick={() => openCameritoWeb("pricing")}
      >
        {t("upgradeToPremium")}
      </Button>
    </Flex>
  );
}
