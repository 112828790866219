import { useCallback, useRef, MouseEvent as ReactMouseEvent, MutableRefObject, RefObject, useMemo } from "react";
import { addGuard } from "@/utils";
import { msToPx, pxToSeconds, secondsToPx } from "./extensions";
import { TimelineHelpers } from "./useTimelineHelpers";
import { RefList } from "./useTimelineUpdater";
import { INDICATOR_PX_WIDTH } from "./const";
import { EventHelpers } from "./useEvents";

type Props = {
  refs: RefList;
  updateMetaTime: Cb;
  Events: EventHelpers;
  TH: TimelineHelpers;
  ignoreNextTimeUpdate: MutableRefObject<boolean>;
};

const SEEK_INTERVAL = 500;

export const useSeeking = ({ refs, updateMetaTime, Events, TH, ignoreNextTimeUpdate }: Props) => {
  const { timeline, video } = refs;
  const isDragging = useRef(false);
  const seekAdjustmentInMs = useRef(0);
  const mouseX = useRef<null | number>(null);
  const initialMouseX = useRef<null | number>(null);
  const blockedMouseX = useRef<{ pos: number | null; dir: ("right" | "left") | null }>({ pos: null, dir: null });
  const wasPausedBeforeSeek = useRef(false);
  const seekIntervalControl = useRef<{ cb: ((p?: boolean) => void) | null; interval: NodeJS.Timeout | null }>({
    cb: null,
    interval: null
  });
  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const scrollableElement = useRef<HTMLDivElement | null>(null);
  const maxScrollPosition = useRef(0);
  const defaultScrollPosition = useRef(0);

  const getCurrentSeekPosition = () => {
    const { left, width } = document.querySelector("#timeline-container")!.getBoundingClientRect();
    return left + width / 2;
  };

  const receivedUpdateWhileSeeking = (ms: number) => {
    if (!isDragging.current) return;
    seekAdjustmentInMs.current = ms;
    boundOffsetInPx.current += msToPx(ms);
  };

  const consumePossibleTimeUpdateInPx = () => {
    const update = seekAdjustmentInMs.current;
    if (update) seekAdjustmentInMs.current = 0;
    return msToPx(update);
  };

  const addToCurrentTime = useCallback(
    (seconds: number) => {
      if (!video.current) return;
      video.current.currentTime = video.current.currentTime + seconds;
      log.timeline("New currentTime: ", video.current.currentTime);
    },
    [video]
  );

  const onTimelineClick = useCallback(
    (pageX: number) => {
      const seekDistance = pageX - getCurrentSeekPosition();
      const currentTime = pxToSeconds(seekDistance);
      addToCurrentTime(currentTime);
    },
    [addToCurrentTime]
  );

  const onMouseMove = (e: MouseEvent) => (mouseX.current = e.pageX);

  const boundOffsetInPx = useRef(0);
  const setIsOutOfBounds = useCallback(
    (maxSeekConstraint: number) => {
      let isOutOfBounds: "right" | "left" | false = false;
      const { left: timelineLeftPos, right: timelineRightPos, width } = timeline.current!.getBoundingClientRect();
      const safe_offest = secondsToPx(2) - boundOffsetInPx.current;

      if (timelineLeftPos + safe_offest >= maxSeekConstraint) {
        timeline.current!.style.left = `-${safe_offest}px`;
        isOutOfBounds = "left";
      }
      if (maxSeekConstraint + safe_offest >= timelineRightPos) {
        timeline.current!.style.left = `${-width + safe_offest + INDICATOR_PX_WIDTH / 2}px`;
        isOutOfBounds = "right";
      }
      if (isOutOfBounds && !blockedMouseX.current.pos) {
        // should not do anything for scrolling, just fit condition
        blockedMouseX.current.pos = mouseX.current || scrollDistance.current;
        blockedMouseX.current.dir = isOutOfBounds;
      }
      if (!isOutOfBounds) {
        blockedMouseX.current = { pos: null, dir: null };
        boundOffsetInPx.current = 0;
      }
    },
    [timeline]
  );

  const preventTimelineAutoMovement = useCallback(() => (ignoreNextTimeUpdate.current = true), [ignoreNextTimeUpdate]);

  const stopDrag = useCallback(
    (e?: MouseEvent) => {
      if (seekIntervalControl.current.cb && seekIntervalControl.current.interval) {
        clearInterval(seekIntervalControl.current.interval);
        seekIntervalControl.current.cb(e?.pageX === initialMouseX.current);
        seekIntervalControl.current.interval = null;
        seekIntervalControl.current.cb = null;
      }
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", stopDrag);
      isDragging.current = false;

      if (!wasPausedBeforeSeek.current) addGuard(() => video.current!.play(), { onError: (err) => log.err(err) });
      if (e?.pageX === initialMouseX.current) {
        onTimelineClick(e.pageX);
        Events.updateMetaEventOnSeek();
        updateMetaTime();
      }

      initialMouseX.current = null;
      mouseX.current = null;
      blockedMouseX.current = { pos: null, dir: null };
      scrollDistance.current = 0;
      boundOffsetInPx.current = 0;
    },
    [Events, onTimelineClick, updateMetaTime, video]
  );

  const startDrag = useCallback(
    (e?: ReactMouseEvent, isScrolling?: boolean) => {
      wasPausedBeforeSeek.current = video.current?.paused || false;
      video.current?.pause();
      initialMouseX.current = isScrolling ? 0 : e?.pageX || 0;
      const seekPosition = getCurrentSeekPosition();
      const diff = seekPosition - timeline.current!.getBoundingClientRect().left;

      const animateDrag = () => {
        if (!timeline.current) return;
        if (!isDragging.current) return;
        let distance: number;

        if (isScrolling) distance = diff + scrollDistance.current;
        else if (mouseX.current === null) distance = diff;
        else distance = diff + e!.pageX - mouseX.current;

        timeline.current.style.left = `${distance * -1}px`;
        setIsOutOfBounds(seekPosition);
        if (isDragging.current) requestAnimationFrame(animateDrag);
        else mouseX.current = null;
      };

      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", stopDrag);
      isDragging.current = true;
      requestAnimationFrame(animateDrag);

      let previousSeekDistance = 0;
      seekIntervalControl.current.cb = (wasTimelineClicked?: boolean) => {
        initialMouseX.current! -= consumePossibleTimeUpdateInPx();
        let seekDistance = 0;
        if (isScrolling) {
          seekDistance = scrollDistance.current + (initialMouseX.current || 0);
        } else {
          seekDistance =
            initialMouseX.current! - (blockedMouseX.current.pos || mouseX.current || initialMouseX.current!);
        }
        if (!wasTimelineClicked) preventTimelineAutoMovement();
        if (blockedMouseX.current.dir === "right") {
          video.current!.currentTime = TH.getTimelineMaxTime();
        } else if (blockedMouseX.current.dir === "left") {
          video.current!.currentTime = TH.getTimelineMinTime();
        } else addToCurrentTime(pxToSeconds(seekDistance - previousSeekDistance));
        previousSeekDistance = seekDistance;
        if (!wasTimelineClicked) {
          Events.updateMetaEventOnSeek();
          updateMetaTime();
        }
      };
      seekIntervalControl.current.interval = setInterval(seekIntervalControl.current.cb, SEEK_INTERVAL);
    },
    [
      Events,
      TH,
      addToCurrentTime,
      preventTimelineAutoMovement,
      setIsOutOfBounds,
      stopDrag,
      timeline,
      updateMetaTime,
      video
    ]
  );

  const setScrollableElements = ({
    containerEl,
    scrollEl
  }: {
    containerEl: RefObject<HTMLDivElement>;
    scrollEl: RefObject<HTMLDivElement>;
  }) => {
    scrollableContainer.current = containerEl.current;
    scrollableElement.current = scrollEl.current;
    if (scrollableContainer.current) {
      scrollableContainer.current.scrollLeft = 1;
    }
  };

  const setScrollableValues = () => {
    if (!scrollableContainer.current || !scrollableElement.current) return;
    if (defaultScrollPosition.current) return;

    const containerWidth = Math.floor(scrollableContainer.current.getBoundingClientRect().width);
    const elementWidth = Math.floor(scrollableElement.current.getBoundingClientRect().width);

    maxScrollPosition.current = elementWidth - containerWidth;
    defaultScrollPosition.current = Math.floor(elementWidth / 3.33);
    scrollableContainer.current.scrollLeft = defaultScrollPosition.current;
    previousScrollPosition.current = defaultScrollPosition.current;
  };

  const ignoreFirstScrollEvent = useRef(true);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  const previousScrollPosition = useRef(0);
  const scrollDistance = useRef(0);

  const clearScrollTimeout = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
      scrollTimeout.current = null;
    }
  };
  const blockScroll = () => (scrollableContainer.current!.scrollLeft = previousScrollPosition.current);

  const onScroll = useCallback(() => {
    if (!scrollableContainer.current) return;
    if (ignoreFirstScrollEvent.current) {
      ignoreFirstScrollEvent.current = false;
      return;
    }
    setScrollableValues();

    const scrollPosition = scrollableContainer.current.scrollLeft;
    const block = blockedMouseX.current;
    const defaultScrollPos = defaultScrollPosition.current;

    if (scrollPosition === 0) {
      if (block.dir === "left") {
        blockScroll();
        return;
      }
      scrollDistance.current -= 1;
      previousScrollPosition.current = defaultScrollPos;
      scrollableContainer.current.scrollLeft = defaultScrollPos;
    } else if (scrollPosition === maxScrollPosition.current) {
      if (block.dir === "right") {
        blockScroll();
        return;
      }
      scrollDistance.current += 1;
      previousScrollPosition.current = defaultScrollPos;
      scrollableContainer.current.scrollLeft = defaultScrollPos;
    } else {
      const scrollDifference = Math.abs(previousScrollPosition.current) - Math.abs(scrollPosition);

      if (
        block.dir &&
        ((Math.sign(scrollDifference) === 1 && block.dir === "left") ||
          (Math.sign(scrollDifference) === -1 && block.dir === "right"))
      ) {
        blockScroll();
        return;
      }
      previousScrollPosition.current = scrollPosition;
      scrollDistance.current -= scrollDifference;
    }

    if (!isDragging.current) startDrag(undefined, true);
    if (scrollTimeout.current) clearScrollTimeout();
    scrollTimeout.current = setTimeout(() => {
      clearScrollTimeout();
      stopDrag();
    }, SEEK_INTERVAL / 2);
  }, [startDrag, stopDrag]);

  return useMemo(
    () => ({
      startDrag,
      addToCurrentTime,
      receivedUpdateWhileSeeking,
      onScroll,
      setScrollableElements
    }),
    [addToCurrentTime, onScroll, startDrag]
  );
};
