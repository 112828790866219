import { createContext, useContext } from "react";
import { useViewer } from "viewer/store/viewer";

type Connect = (jid: string, listId: number) => Promise<void>;
type Ctx = { connect: Connect; isConnected: boolean };

const ConnectContext = createContext<Ctx>({
  connect: async (jid, listId) => log.warn("Context not ready"),
  isConnected: false
});

export const useConnect = () => useContext(ConnectContext);

export default function ConnectProvider({ children, connect }: { children: any; connect: Connect }) {
  const { selectedCameraJid, cameraConfigs } = useViewer((s) => ({
    selectedCameraJid: s.selectedCamera.jid,
    cameraConfigs: s.cameraConfigs
  }));

  const isConnected = Boolean(selectedCameraJid && cameraConfigs[selectedCameraJid]?.cameraState);
  return <ConnectContext.Provider value={{ connect, isConnected }}>{children}</ConnectContext.Provider>;
}
