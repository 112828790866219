import { dataSyncEmitter } from "@/modules/events/emitter";
import { useEffect, useRef } from "react";
import { msToPx, secondsToPx } from "./extensions";
import { TimelineHelpers } from "./useTimelineHelpers";
import { RefList } from "./useTimelineUpdater";

type Props = {
  refs: RefList;
  TH: TimelineHelpers;
};

export const useTimelineVisuals = ({ refs, TH }: Props) => {
  const { timelineStartDate, timeline, originalTimelineStartDate, video, backgroundSvg } = refs;
  const prevRotation = useRef<ImageRotation>("ROTATION_0");
  const imageRotations = useRef<{ startTime: number; rotation: ImageRotation }[]>([]);
  const prevTimelineStartPosition = useRef(0);

  const updateTimelineWidth = (e: Event) => {
    const { detail } = e as TimelinePlaylistEvent;
    const { duration, startDate: startTime } = detail;
    const startDate = new Date(startTime).toISOString();

    timelineStartDate.current = startDate;
    if (!originalTimelineStartDate.current) originalTimelineStartDate.current = startDate;
    if (timeline.current) timeline.current.style.width = `${msToPx(duration)}px`;
  };

  const addImageRotation = (e: Event) => {
    const { detail } = e as TimelineFileEvent;
    const { date, imageRotation } = detail;
    if (!imageRotation) return;
    const newImageRotation = { startTime: new Date(date).getTime(), rotation: imageRotation };
    const alreadyExists = imageRotations.current.findIndex((i) => i.startTime === newImageRotation.startTime) !== -1;
    if (alreadyExists) return;

    imageRotations.current.push(newImageRotation);
    imageRotations.current.sort((a, b) => a.startTime - b.startTime);
  };

  const rotatePlayerBasedOnImageRotation = () => {
    const currentTime = TH.getCurrentPlayerDateTime();
    let currentRotation = prevRotation.current;
    imageRotations.current.forEach(({ rotation, startTime }) => {
      if (currentTime >= startTime) currentRotation = rotation;
    });

    const videoEl = refs.video.current;
    if (currentRotation !== prevRotation.current && videoEl) {
      const aspectRatio = videoEl.videoWidth / videoEl.videoHeight;
      const disableManualRotation =
        (aspectRatio > 1 && (currentRotation === "ROTATION_180" || currentRotation === "ROTATION_0")) ||
        (aspectRatio < 1 && (currentRotation === "ROTATION_90" || currentRotation === "ROTATION_270"));

      if (currentRotation === "ROTATION_0" || currentRotation === "ROTATION_180")
        dataSyncEmitter.emit("player-orientation-change", {
          isEnabled: !disableManualRotation,
          orientation: "landscape"
        });
      else
        dataSyncEmitter.emit("player-orientation-change", {
          isEnabled: !disableManualRotation,
          orientation: "vertical"
        });

      prevRotation.current = currentRotation;
    }
  };

  const adjustBackgroundSvgPosition = () => {
    if (backgroundSvg.current)
      backgroundSvg.current.style.backgroundPositionX = `-${secondsToPx(
        (new Date(timelineStartDate.current!).getTime() / 1000) % 60
      )}px`;
  };

  // const moveTimeline = (movementInMs: number) => {
  //   if (!timeline.current) return;
  //   adjustBackgroundSvgPosition();
  //   const prevPosition = Number(timeline.current.style.left.split("px")[0]);
  //   timeline.current.style.left = `-${Math.abs(prevPosition - msToPx(movementInMs))}px`;
  // };

  const getTimelineStartPositionInMs = () => {
    const startTime = TH.getStartTime();
    const originalStartTime = TH.getOriginalStartTime();
    if (!startTime || !originalStartTime || !video.current) return 0;
    const originalTime = video.current.currentTime;
    const offset = (new Date(startTime).getTime() - new Date(originalStartTime).getTime()) / 1000;
    const timeWithOffset = originalTime - offset;
    if (timeWithOffset < 0) return 0;
    const adjustedTime = Math.min(timeWithOffset, originalTime);
    return adjustedTime;
  };

  const moveTimeline = () => {
    if (!timeline.current) return;
    const timelineStartPositionInMs = Math.round(getTimelineStartPositionInMs());

    if (timelineStartPositionInMs !== prevTimelineStartPosition.current) {
      adjustBackgroundSvgPosition();
      timeline.current.style.left = `-${secondsToPx(timelineStartPositionInMs)}px`;
    }
    prevTimelineStartPosition.current = timelineStartPositionInMs;
  };

  useEffect(() => {
    document.addEventListener("hls-init-file", addImageRotation);
    return () => {
      document.removeEventListener("hls-init-file", addImageRotation);
    };
  }, []);

  return {
    moveTimeline,
    rotatePlayerBasedOnImageRotation,
    updateTimelineWidth
  };
};
