import { Flex, Heading, Box, Text } from "@chakra-ui/react";
import { t } from "@/utils";
import AppImage from "@/components/AppImage";

export default function NoEvents() {
  return (
    <Flex w="100%" justifyContent="center" alignItems="center" flexDir="column" h="100%">
      <AppImage
        name="no_events.svg"
        options={{
          imgProps: {
            htmlWidth: 264,
            htmlHeight: 192,
            mb: "2rem"
          }
        }}
      />
      <Box maxW="16.5rem" textAlign="center">
        <Heading as="h3" mb="12px">
          {t("noEventsToDisplay")}
        </Heading>
        <Text>{t("noEvents.caption")}</Text>
      </Box>
    </Flex>
  );
}
