import { MutableRefObject, RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { dataSyncEmitter } from "@/modules/events/emitter";
import { useElementHelpers } from "./useElementHelpers";
import { useTimelineHelpers } from "./useTimelineHelpers";
import { useSeeking } from "./useSeeking";
import { useEvents } from "./useEvents";
import { useTimestamps } from "./useTimestamps";
import { useTimelineVisuals } from "./useTimelineVisuals";
import { msToPx } from "./extensions";
import useVideoRanges from "./useVideoRanges";

export type RefList = {
  video: MutableRefObject<HTMLVideoElement | null>;
  container: RefObject<HTMLDivElement>;
  timeline: RefObject<HTMLDivElement>;
  indicator: RefObject<HTMLDivElement>;
  backgroundSvg: RefObject<HTMLDivElement>;
  timelineStartDate: MutableRefObject<string | null>;
  originalTimelineStartDate: MutableRefObject<string | null>;
};
export type PRange = {
  id: string;
  start: number;
  end: number;
  duration: number;
};

export default function useTimelineUpdater() {
  const video = useRef<HTMLVideoElement | null>(null);
  const container = useRef<HTMLDivElement>(null);
  const timeline = useRef<HTMLDivElement>(null);
  const indicator = useRef<HTMLDivElement>(null);
  const backgroundSvg = useRef<HTMLDivElement>(null);

  const timelineStartDate = useRef<string | null>(null);
  const originalTimelineStartDate = useRef<string | null>(null);

  const refs: RefList = useMemo(
    () => ({
      video,
      timeline,
      container,
      indicator,
      timelineStartDate,
      originalTimelineStartDate,
      backgroundSvg
    }),
    []
  );

  // const prevCurrentTimeInMs = useRef(0);
  const ignoreNextTimeUpdate = useRef(false);
  const TH = useTimelineHelpers({ refs });
  const elementHelpers = useElementHelpers({ refs, TH });
  const Events = useEvents({ elementHelpers, refs, TH });
  const Visuals = useTimelineVisuals({ refs, TH });
  const { renderTimestamps, updateTimestamps } = useTimestamps({
    refs,
    elementHelpers,
    TH
  });
  const { hasVideoDataForCurrentTime, renderPlayableRanges } = useVideoRanges({ elementHelpers, refs, TH });

  const updateMetaTime = useCallback(() => {
    const event = new CustomEvent("meta-time-update", {
      detail: { time: TH.getCurrentPlayerDateTime(), hasDataForTime: hasVideoDataForCurrentTime() }
    });
    document.dispatchEvent(event);
  }, [TH, hasVideoDataForCurrentTime]);

  const { startDrag, receivedUpdateWhileSeeking, onScroll, setScrollableElements } = useSeeking({
    refs,
    Events,
    TH,
    updateMetaTime,
    ignoreNextTimeUpdate
  });

  // const checkAndFixOldPlayback = useCallback(
  //   (e: Event) => {
  //     const { detail } = e as TimelinePlaylistEvent;
  //     const { startDate: newStartTime } = detail;
  //     const currentTime = TH.getCurrentPlayerDateTime();
  //     if (currentTime < newStartTime) {
  //       log.timeline("Fixing old playback");
  //       const seekTime = newStartTime - currentTime + 10000;
  //       addToCurrentTime(msToSeconds(seekTime));
  //     }
  //   },
  //   [TH, addToCurrentTime]
  // );

  const onTimeUpdate = useCallback(() => {
    if (!video.current) return;

    // const currentTimeInMs = secondsToMs(video.current.currentTime);
    // const timeUpdate = currentTimeInMs - prevCurrentTimeInMs.current;
    // prevCurrentTimeInMs.current = currentTimeInMs;

    Events.updateSkipButtons();
    if (!ignoreNextTimeUpdate.current) Visuals.moveTimeline();
    else ignoreNextTimeUpdate.current = false;
    updateMetaTime();
    Events.updateMetaEventsOnTimeUpdate();
    Visuals.rotatePlayerBasedOnImageRotation();
  }, [Events, Visuals, updateMetaTime]);

  const onPlaylistUpdate = useCallback(
    (e: Event) => {
      Visuals.updateTimelineWidth(e);
      // checkAndFixOldPlayback(e);
      renderTimestamps();
      Events.deleteOldEvents();

      const negativeTimeUpdateInMs = TH.getNegativeTimeUpdate();
      if (negativeTimeUpdateInMs >= 0) {
        // we have only new data
        renderPlayableRanges(e);
      } else {
        receivedUpdateWhileSeeking(negativeTimeUpdateInMs);
        // data were deleted from timeline
        Visuals.moveTimeline();
        renderPlayableRanges(e, true);
        updateTimestamps(msToPx(negativeTimeUpdateInMs));
        Events.updateRenderedEvents(negativeTimeUpdateInMs);
      }

      dataSyncEmitter.emit("timeline-render");
    },
    [Visuals, renderTimestamps, TH, renderPlayableRanges, receivedUpdateWhileSeeking, updateTimestamps, Events]
  );

  useEffect(() => {
    document.addEventListener("playlist-update", onPlaylistUpdate);
    return () => {
      document.removeEventListener("playlist-update", onPlaylistUpdate);
    };
  }, [onPlaylistUpdate]);

  return {
    onTimeUpdate,
    startDrag,
    onScroll,
    setScrollableElements,
    video,
    indicator,
    timeline,
    container,
    backgroundSvg
  };
}
